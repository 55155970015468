import NavBar from './NavBar';
import Content from './Content';
import Status from './Status';
import Swal from 'sweetalert2';

let isUserActive = localStorage.getItem('isUserActive');

const MainApp = () => {

    if(isUserActive && isUserActive == "false") {

        Swal.fire('Access Denied!', 'Please login to your account first.', 'error')
        .then(() => {
            window.open('./', '_self');
        });
        
    }
    
    return(
        <>
            <NavBar />
            <Content />
            <Status />
        </>
    );
}

export default MainApp;
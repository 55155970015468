// Components
import Login from './components/homepage/Login';
import MainApp from './components/mainApp/MainApp';
import SaveMemoryFeature from './components/saveMemoryFeature/SaveMemoryFeature'; 
import Memories from './components/memoryHistory/Memories';


import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />}></Route>
        <Route path='/app' element={<MainApp />}></Route>
        <Route path='/saveMemory' element={<SaveMemoryFeature />}></Route>
        <Route path='/memories' element={<Memories />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

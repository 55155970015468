import React, { useEffect, useState } from 'react';
import './stylesheets/Status.css';
import pfp from '../assets/default.png';
import axios from 'axios';

let relationId = localStorage.getItem('relationId');
let userId = localStorage.getItem('userId');

const Status = () => {

    const [partnerStatus, setPartnerStatus] = useState('');

    const serializeJson = data => {
        data = data.replace('"',''); // remove first " quote
        return data.replace('"',''); // remove the last " quote then return
    }

    useEffect(() => {
        // Fetch partner status on component mount
        getPartnerStatus();
    
        // Set up an interval to fetch partner status every 1 seconds
        const intervalId = setInterval(() => {
          getPartnerStatus();
        }, 1000);
    
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      }, []);

    const getPartnerStatus = async () => {

        try {
            
            const response = await axios.get(`https://memento.jazcodeit.ca/backend/appDriver.php?relationId=${relationId}&userId=${userId}`);

            // get the json data from the backend
            let partnerUsername = JSON.stringify(response.data.partnerUsername);
            let partnerUserId = JSON.stringify(response.data.partnerUserId);
            let partnerStatus = JSON.stringify(response.data.partnerStatus);

            // serialize json data
            partnerUsername = serializeJson(partnerUsername);
            partnerUserId = serializeJson(partnerUserId);
            partnerStatus = serializeJson(partnerStatus);

            // store data in localStorage
            localStorage.setItem('partnerUsername', partnerUsername);
            localStorage.setItem('partnerUserId', partnerUserId);
            localStorage.setItem('partnerStatus', partnerStatus);

            // store data in state
            setPartnerStatus(partnerStatus);

        } catch (error) {
            console.log("Error fetching data: " + error);
        }

    }

    return(
        <div className='Status'>
            <div>
                <img className='img-fluid pfpPartnerUser' src={pfp} alt='Profile Picture' />
            </div>
            <div>
                <div className="alert alert-primary" role="alert">
                    <h4 className="alert-heading">{localStorage.getItem('partnerUsername')} Status</h4>
                    <p>{partnerStatus}</p>
                </div>
            </div>
        </div>
    );
}

export default Status;
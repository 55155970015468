import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import './stylesheets/MemoryForm.css';

let isMemorySaved = localStorage.getItem('isMemorySaved');
let isCountdownFinished = localStorage.getItem('isCountdownFinished');

const MemoryForm = () => {

    const [formData, setFormData] = useState({
        userId: localStorage.getItem('userId'),
        memorySummary: '',
        memoryImage: null,
        saveMemoryBtn: false,
    });
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            if(isCountdownFinished && isCountdownFinished === "true") {

                const formDataToSend = new FormData();
                formDataToSend.append('userId', formData.userId);
                formDataToSend.append('memorySummary', formData.memorySummary);
                formDataToSend.append('memoryImage', formData.memoryImage);
                formDataToSend.append('saveMemoryBtn', formData.saveMemoryBtn);

                await axios.post('https://memento.jazcodeit.ca/backend/appDriver.php', formDataToSend, {
                    headers: {
                    'content-type': 'multipart/form-data',
                    }
                }).then(() => {

                    Swal.fire('Memories Saved', 'Your most memorable image and memories have been saved succesfully! You can view this in Memories section of the App', 'success','OK')
                    .then(() => {
                        localStorage.setItem('isMemorySaved', true);
                        window.open('/app', '_self');
                    });

                
                }).catch(() => {

                    Swal.fire('Memories not saved!', 'There is an Error saving your memories. Please try again', 'error','Try Again');
                    localStorage.setItem('isMemorySaved', false);
            
                });

            } else {

                Swal.fire('Countdown not Finished!', 'Wait for the countdown to finish to upload Memory.', 'error');

            }


        } catch(error) {
            console.log('Network Error: ' + error.message);
        }
    }

    const handleChange = event => {
        const { name, value, type } = event.target;

        if (type === 'file') {
            console.log('file detected! ');
            setFormData({ ...formData, [name]: event.target.files[0] });
          } else {
            setFormData({ ...formData, [name]: value });
          }
    }

    // check if user already uploaded memories
    if(isMemorySaved && isMemorySaved === "true")
        Swal.fire('Not Allowed!', 'You have already saved a memories for this month. It is only allowed once a month', 'error','OK')
        .then(() => {
            window.open('./app', '_self');
        });

    return(
        <div className='MemoryForm'>

            <form method='POST' onSubmit={handleSubmit} encType='multipart/form-data'>

                <div className='uploadText'>
                    <h1>Most Memorable Image</h1>
                    <p>Upload the most memorable image for this month</p>

                    <input onChange={handleChange} name='memoryImage' type='file' className='form-control' />
                </div>

                
                <div className='summaryText'>
                    <h1>Memories Summary</h1>
                    <p>Write the summary of most memorable that happened for this month</p>

                    <textarea maxLength='255' type='text' value={formData.memorySummary} onChange={handleChange} name='memorySummary' id='summaryTextarea' className='form-control' placeholder='Write memory summary...' rows='8' autoSave='true' autoComplete='false' required></textarea>
                </div>
                

                <button type='submit' name='saveMemoryBtn' className='btn btn-primary saveMemoryBtn'>Save Memory</button>
            
            </form>

        </div>
    );
}

export default MemoryForm;
import React, { useState, useEffect } from 'react';
import './stylesheets/Content.css';
import Swal from 'sweetalert2';

const Content = () => {

  const [countdownData, setCountdownData] = useState({
    daysRemaining: 0,
    hoursRemaining: 0,
    minutesRemaining: 0,
    secondsRemaining: 0,
    isCountdownFinished: false,
  });

  const { daysRemaining, hoursRemaining, minutesRemaining, secondsRemaining, isCountdownFinished } = countdownData;

  const countdown = (targetDay) => {
    const currentDate = new Date();
    const targetDate = new Date();

    if (currentDate.getDate() > targetDay) {
      targetDate.setMonth(currentDate.getMonth() + 1);
      targetDate.setDate(targetDay);
      targetDate.setHours(0, 0, 0, 0);
    } else {
      targetDate.setDate(targetDay);
      targetDate.setHours(0, 0, 0, 0);
    }

    const targetDateCountdown = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDay, 0, 0, 0, 0);
    const timeRemaining = targetDateCountdown - currentDate;

    const newDaysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const newHoursRemaining = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const newMinutesRemaining = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const newSecondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    let newIsCountdownFinished = false;
    if (currentDate.getDate() === targetDay && currentDate.getHours() >= 0 && currentDate.getHours() <= 23) {
      localStorage.setItem('isCountdownFinished', true);
      newIsCountdownFinished = true;
    } else {
      localStorage.setItem('isCountdownFinished', false);
    }

    setCountdownData((prevCountdownData) => ({
      ...prevCountdownData,
      daysRemaining: newDaysRemaining,
      hoursRemaining: newHoursRemaining,
      minutesRemaining: newMinutesRemaining,
      secondsRemaining: newSecondsRemaining,
      isCountdownFinished: newIsCountdownFinished,
    }));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      countdown(29);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const saveMemory = () => {
    window.open('/saveMemory', '_self');
  };

  // if countdown is running
  if(isCountdownFinished == false)
    // reset save memory feature
    localStorage.setItem('isMemorySaved', false);


  return (
    <div className='Content'>
      {isCountdownFinished ? (
        <div className='countdownFinished'>
          <h1>Happy Monthsary!</h1>
        </div>
      ) : (
        <div className='countdown'>
          <h1>{daysRemaining} Days</h1>
          <h3>
            {hoursRemaining} Hours and {minutesRemaining} Minutes<br/>
            <b className='text-danger'>{secondsRemaining} seconds</b>
          </h3>
        </div>
      )}

      <div className='countdownText'>
        {isCountdownFinished ? (
          <p className='after'>Save the most memorable memories you have for this month</p>
        ) : (
          <p className='before'>Before your monthsary</p>
        )}
      </div>

      <div className='mainFeatureBtn'>
        {isCountdownFinished ? (
          <button className='btn btn-primary' onClick={saveMemory}>
            Save Memory
          </button>
        ) : (
          <button
            className='btn btn-primary notReady'
            onClick={() => {
              Swal.fire(
                'Not Allowed!',
                'You have to wait until your monthsary before you can save memory. Keep an eye on the Countdown 👀',
                'error',
                'OK'
              );
            }}
          >
            Save Memory
          </button>
        )}
      </div>
    </div>
  );
};

export default Content;

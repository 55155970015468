import ReturnBtn from './ReturnBtn';
import MemoriesContent from './MemoriesContent';


const Memories = () => {
    return(
        <>
            <ReturnBtn />
            <MemoriesContent />
        </>
    );
}

export default Memories;
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import './stylesheets/NavBar.css';
import pfp from '../assets/default.png';
import axios from 'axios';


const NavBar = () => {

    const serializeJson = data => {
        data = data.replace('"',''); // remove first " quote
        return data.replace('"',''); // remove the last " quote then return
    }

    const updateStatusToDatabase = async () => {

        try {
            
            const formToSend = new FormData();
            formToSend.append('newStatus', localStorage.getItem('status'));
            formToSend.append('userId', localStorage.getItem('userId'));

            const response = await axios.post('https://memento.jazcodeit.ca/backend/appDriver.php', formToSend);

            console.log(response.data);
            if(JSON.stringify(response.data.isUpdated) && JSON.stringify(response.data.isUpdated) === "true") {
                    
                // get the json data from the backend
                let newStatus = JSON.stringify(response.data.newStatus);
                    
                // serialize json data
                newStatus = serializeJson(newStatus);

                // store data in locaStorage
                localStorage.setItem('status', newStatus);
            }
            
        } catch (error) {
            console.log("Network Error: " + error);
        }

    }

    const updateStatusAlert = async () => {

        const { value: text } = await Swal.fire({
            title: 'Update Status',
            input: 'textarea',
            inputLabel: 'Let your Partner know what you currently doing',
            inputPlaceholder: 'Type your status here...',
            inputAttributes: {
                'aria-label': 'Type your status here'
            },
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                  return 'You need to write something!'
                }
              }
        })
    
        if (text) {
            Swal.fire("Status updated to '" + text + "'");
            localStorage.setItem('status', text);
            updateStatusToDatabase();
        }
    
    }

    const selector = () => {
        Swal.fire({
            title: 'More Options',
            showDenyButton: true,
            showCancelButton: true,
            cancelButtonText: 'Close',
            cancelButtonColor: '#ff0000',
            confirmButtonText: 'Update status',
            confirmButtonColor: '#000000',
            denyButtonText: `Memories`,
            denyButtonColor: '#000000',
          }).then((result) => {
            if (result.isConfirmed) {
                updateStatusAlert();
            } else if (result.isDenied) {
                window.open('/memories', '_self');
            }
          });
    }

    return(
        <div className='NavBar'>
            <div>
                <img className='img-fluid pfpMainUser' src={pfp} alt='Profile' />
            </div>
            <div>
                <button onClick={selector} className='btn btn-light'> More Options</button>
            </div>
        </div>
    );
}

export default NavBar;
import React from 'react';
import './stylesheets/ReturnBtn.css';

const goBack = () => {
    window.open('./app', '_self')
}

const ReturnBtn = () => {
    return(
        <div className='ReturnBtn'>
            <button id='goBackBtn' className='btn btn-danger' onClick={goBack}>Go back to Homepage</button>
        </div>
    );
};

export default ReturnBtn;
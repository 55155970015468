import ReturnBtn from './ReturnBtn';
import MemoryForm from './MemoryForm';


const SaveMemoryFeature = () => {
    return(
        <>
            <ReturnBtn />
            <MemoryForm />
        </>
    );
}

export default SaveMemoryFeature;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './stylesheets/MemoriesContent.css';

let relationId = localStorage.getItem('relationId');

const MemoriesContent = () => {

    const [memories, setMemories] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`https://memento.jazcodeit.ca/backend/appDriver.php?relationId=${relationId}`);

            // Use a regular expression to find and extract individual JSON objects
            const regex = /{.*?}/g;
            const memoryStrings = response.data.match(regex);

            // Parse each extracted JSON string into a memory object
            const memories = memoryStrings.map(memoryString => JSON.parse(memoryString));

            setMemories(memories)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return(
        <div className='MemoriesContent'>

            <h1>Memories</h1>


{memories.map((memory, key) => (
<div key={key} className="card-group mb-3 m-2">
  <div className="card">
    <img src={memory.memoryImgPath} className="card-img-top img-fluid img-thumbnail" alt="Memento App" />
    <div className="card-body">
      <h5 className="card-title">Memory submitted by <u>{memory.username}</u></h5>
      <p className="card-text">{memory.writtenMemory}</p>
    </div>
    <div className="card-footer">
      <small className="text-body-secondary">Memory Uploaded on {memory.memoryDate}</small>
    </div>
  </div>
</div>
))}


            <p className='footer'>That's all for now, More memories to come...</p>

        </div>
    );
}

export default MemoriesContent;
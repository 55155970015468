import '../node_modules/bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Swal from 'sweetalert2';
import './index.css';
import App from './App';

let isUserActive;

if(localStorage.getItem('isUserActive')) {
  isUserActive = localStorage.getItem('isUserActive');
}

if(isUserActive && isUserActive === "true") {

  localStorage.setItem('isUserActive', true);
  
} else {

  localStorage.setItem('isUserActive', false);

}


if(window.innerWidth > 393 && window.innerHeight > 851) {

  Swal.fire('Incompatible Device', 'Cannot run this app on this device.', 'error');

  if(document.getElementById('root'))
    document.getElementById('root').style.display = "none";
  else
    document.body.style.display = "none";

}


// check if standalone
if(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1"){
  console.log("Development Mode: Enabled");
} else {

  if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
    // The app is running in standalone mode (PWA)
    console.log('Running in standalone mode.');
  } else {
    // The app is not running in standalone mode
    Swal.fire('Access Denied!', 'Please add to homepage to run the app.', 'error');
    if(document.getElementById('root'))
      document.getElementById('root').style.display = "none";
    else
      document.body.style.display = "none";
  }

}

// document.body.addEventListener("click", () => {
//   Notification.requestPermission().then(perm => {
//     if(perm === "granted")
//         new Notification("hi");
//   }).catch(error => {
//     alert(error);
//   });
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import './stylesheets/Login.css';

let isUserActive = localStorage.getItem('isUserActive');

const Login = () => {

    const serializeJson = data => {
        data = data.replace('"',''); // remove first " quote
        return data.replace('"',''); // remove the last " quote then return
    }

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        loginBtn: false,
    });


    const handleSubmit = async event => {
        event.preventDefault();

        try {

            const formDataToSend = new FormData();
            formDataToSend.append('email', formData.email);
            formDataToSend.append('password', formData.password);
            formDataToSend.append('loginBtn', formData.loginBtn);

            const response = await axios.post('https://memento.jazcodeit.ca/backend/appDriver.php', formDataToSend);

            if(JSON.stringify(response.data.isAuth) && JSON.stringify(response.data.isAuth) === "true") {

                console.log('Login: ' + JSON.stringify(response.data.isAuth));

                localStorage.setItem('isUserActive', true);

                // get the json data from the backend
                let userId = JSON.stringify(response.data.userId);
                let username = JSON.stringify(response.data.username);
                let status = JSON.stringify(response.data.status);
                let relationId = JSON.stringify(response.data.relationId);

                // serialize json data
                userId = serializeJson(userId);
                username = serializeJson(username);
                status = serializeJson(status);
                relationId = serializeJson(relationId);

                // store data in locaStorage
                localStorage.setItem('userId', userId);
                localStorage.setItem('username', username);
                localStorage.setItem('status', status);
                localStorage.setItem('relationId', relationId);

                // redirect
                window.open('/app', '_self');

            } else {

                console.log('Login: ' + JSON.stringify(response.data));
                localStorage.setItem('isUserActive', false);
                Swal.fire('Login Failed!', 'Email or Password incorrect. Make sure you have existing account to login.', 'error','OK');
            
            }


        } catch (error) {
            console.log('Network Error: ' + error);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

    if(isUserActive && isUserActive === "true")
        window.open('/app', '_self');

    return(
        <div className="Login">
            <form method='POST' onSubmit={handleSubmit}>

                <div>
                    <h1>Memento App</h1>
                    <p className="text-white"><b>Save Memories together</b><br/>App created for Kwynn made with love by Jaz ❤️</p>
                </div>

                <input
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    autoComplete="true"
                    autoSave="true"
                    required
                />

                <input
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    autoComplete="true"
                    autoSave="true"
                    required
                />
    
                <button type="submit" name="loginBtn" className="btn btn-primary">Login</button>
            </form>
        </div>       
    );
}

export default Login;